import React from "react";

import { useAppSelector } from "store";

import { Modal } from "../Modal";

import { Color } from "ts/enums/color";
import { ImportSettingsStep } from "./ImportSettingsStep";

export const ImportProgressModal = () => {
  const state = useAppSelector((state) => state.importProcess);
  return (
    <Modal
      isOpen={state.importModalOpen}
      styles={{
        content: {
          margin: "auto",
          boxShadow: "0px 4px 16px #B6BACB29",
          padding: "24px",
          width: "1170px",
          height: "max-content",
          border: `1px solid ${Color.neutral30}`,
          borderRadius: "5px",
          backgroundColor: Color.white,
          transition: "height .5s ease-in-out",
          overflow: "hidden",
        },
      }}
    >
      <ImportSettingsStep />
    </Modal>
  );
};
