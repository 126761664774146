import React, { useState, createRef, useEffect, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { setShowFileUploadingModal } from "store/analysisSettings/dataSourceSlice";
import { uploadFile } from "services/analysis";
import { isDegraded } from "utils/isDegraded";

import { FileUploadInput } from "components/_inputs/FileUpload";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { FileUploadStatus } from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

export const ImportMoreDataButton = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const [analysisContextState] = useContext(AnalysisContext);
  const [isImportError, setIsImportError] = useState<boolean>(false);

  const fileInputRef = createRef<HTMLInputElement>();

  const onImportFile = async (file: File) => {
    setIsImportError(false);
    try {
      dispatch(setShowFileUploadingModal(true));
      await uploadFile(analysisContextState.analysisDetails.id, file);
    } catch (e) {
      dispatch(setShowFileUploadingModal(false));
      setIsImportError(true);
    }
  };

  useEffect(() => {
    if (analysisContextState.analysisDetails.uploadStatus === FileUploadStatus.Failed) {
      dispatch(setShowFileUploadingModal(false));
      setIsImportError(true);
    }
  }, [analysisContextState]); //eslint-disable-line

  return (
    <>
      <Button
        variant={ButtonVariant.outlineBlue}
        size={ButtonSize.ml}
        onClick={() => fileInputRef.current.click()}
        disabled={isDegraded(currentUser, analysisContextState)}
      >
        <Text resource="button.importMoreData" />
      </Button>
      <FileUploadInput
        inputFileRef={fileInputRef}
        onSelectFile={onImportFile}
        resetFileRef={isImportError}
      />
    </>
  );
};
