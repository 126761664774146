import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "store";

import { Icon, IconType } from "components/_icons/Icon";

import { Color } from "ts/enums/color";
import { Select } from "components/_inputs/Select";
import { fetchDataSources } from "store/analysisSettings/thunks";
import { LoadingDots } from "components/LoadingDots";
import { voidFunc } from "utils/voidFunc";

export const DataPreviewTable = () => {
  const dispatch = useAppDispatch();
  const { analysisDataSource } = useAppSelector((state) => state.dataSource);

  const headers = Object.keys(analysisDataSource?.comments?.[0]?.values ?? {});

  const isColumnSelected = (header) =>
    analysisDataSource?.selectedColumns &&
    analysisDataSource?.selectedColumns.some((sc) => sc.toLowerCase() === header.toLowerCase());

  useEffect(() => {
    dispatch(fetchDataSources(5937));
  }, [dispatch]);

  if (!analysisDataSource) {
    return <LoadingDots />;
  }

  return (
    <StyledDataPreviewTable>
      <thead>
        <tr>
          {headers.map((header) => (
            <StyledTableHeaderContainer key={header} isSelected={isColumnSelected(header)}>
              <StyledTableHeader>
                <StyledHeaderTitle>{header}</StyledHeaderTitle>
                <StyledHeaderIconContainer>
                  {isColumnSelected(header) && (
                    <Icon type={IconType.checkCircle} color={Color.white} size={16} />
                  )}
                </StyledHeaderIconContainer>
              </StyledTableHeader>
              <StyledDataTypeSelection>
                <Select
                  buttonStyle={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "2px",
                  }}
                  dropdownWidth="100%"
                  options={[
                    {
                      label: "Text",
                      value: "Text",
                    },
                  ]}
                  selectedOption={{
                    label: "Text",
                    value: "Text",
                  }}
                  handleChange={voidFunc}
                  dropdownPosition={{ top: 35 }}
                />
              </StyledDataTypeSelection>
            </StyledTableHeaderContainer>
          ))}
        </tr>
      </thead>
      <tbody>
        {analysisDataSource.comments.map((comment, index) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={`${index}-${header}`}>
                <StyledTableContentContainer>
                  {comment.values[header] as ReactNode}
                </StyledTableContentContainer>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledDataPreviewTable>
  );
};

const StyledDataPreviewTable = styled.table`
  text-align: left;
  width: auto;
  border-spacing: 0;
  cursor: default;
  border-collapse: separate;
  table-layout: fixed;
  font-size: 0.875em;
  overflow: scroll;

  td {
    border-bottom: 1px solid ${Color.blue20};
    margin: 0px;
    padding: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 1365px) {
    th,
    td {
      max-width: 480px;
    }
  }

  @media (min-width: 1366px) {
    th,
    td {
      max-width: 600px;
    }
  }
`;

const StyledTableHeaderContainer = styled.th<{ isSelected: boolean }>`
  box-sizing: content-box;
  font-weight: bold;
  text-align: left;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  border-top: 1px solid ${Color.blue20};
  border-bottom: 1px solid ${Color.blue20};
  min-width: 95px;
  max-width: 450px;
  vertical-align: top;
  background-color: ${(props) => (props.isSelected ? Color.gray40 : Color.sky20)};
  color: ${(props) => (props.isSelected ? Color.white : Color.gray50)};
`;

const StyledTableHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledHeaderTitle = styled.div`
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 24px;
`;

const StyledHeaderIconContainer = styled.div`
  float: right;
`;

const StyledTableContentContainer = styled.div`
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  color: ${Color.gray50};
`;

const StyledDataTypeSelection = styled.div`
  border-top: 1px solid ${Color.blue20};
  margin-top: 5px;
  padding: 10px 16px;
`;
