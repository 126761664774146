import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { upperFirst } from "lodash";

import { routes } from "routes";
import { useAppDispatch, useAppSelector } from "store";
import { deleteVariableMapping } from "services/variableMappings";
import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import {
  clearState,
  setCurrentEditedRow,
  setCurrentPage,
  setShowDeleteConfirmation,
  updateGlossariesListSorting,
} from "store/glossaries/glossariesListSlice";
import { fetchVariableMappings } from "store/glossaries/thunks";
import { setSearchTerm } from "store/search/searchbarSlice";

import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyState";
import { Button } from "components/_buttons/Button";
import { StyledTablePageHeader, StyledTablePageTitle } from "components/Table/TablePageHeader";
import { ErrorScreen } from "components/ErrorScreen";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { TextTruncator } from "components/TextTruncator";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { Text } from "components/Text";
import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";
import { TableInformation } from "components/Table/ClickableTableHeader";

import emptySvg from "assets/images/variable-mappings-empty-state.svg";
import { FlexTableType } from "ts/enums/flexTable";
import { Color } from "ts/enums/color";
import { RoleType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";
import { VariableMappingsPresetListSortingParameter } from "ts/enums/sorting";

const GLOSSARIES_HEADERS: TableInformation<VariableMappingsPresetListSortingParameter>[] =
  Object.values(VariableMappingsPresetListSortingParameter)
    .filter((x) => x !== "createdDate")
    .map((vm) => ({
      headerName: vm,
      sortingParameter: VariableMappingsPresetListSortingParameter[upperFirst(vm)],
    }));

export const VariableMappingsPage = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.glossariesList);

  // hooks
  const history = useHistory();
  const dispatch = useAppDispatch();

  // functions
  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    const sortColumn = GLOSSARIES_HEADERS[columnIndex].sortingParameter;
    dispatch(updateGlossariesListSorting({ sortColumn, sortOrder: order }));
    dispatch(fetchVariableMappings());
  };

  const deleteMapping = async () => {
    await deleteVariableMapping(state.currentEditedRow.id);
    dispatch(setShowDeleteConfirmation(false));
    dispatch(fetchVariableMappings());
  };

  const getDotsMenuContents = () => {
    return [
      {
        label: <Text resource="variableMappingGroupsRow.cta.goToGroup" />,
        onClick: () => history.push(routes.editSelectedVariableMapping(state.currentEditedRow.id)),
      },
      {
        label: <Text resource="button.delete" />,
        onClick: () => dispatch(setShowDeleteConfirmation(true)),
      },
    ];
  };

  const onSearch = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchVariableMappings());
  };

  const handlePageSelection = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchVariableMappings());
  };

  const onClickCreateMappingButton = () => history.push(routes.editSelectedVariableMapping("new"));

  useEffect(() => {
    dispatch(fetchVariableMappings());

    // Reset state when component unmounts
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  if (currentUser.roleType === RoleType.Viewer) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      {state.isLoading ? (
        <ListPageHeaderPlaceholder
          showCreateButton={true}
          showFilterButton={false}
          applyMorePadding={false}
          buttonPlaceholderWidth={236}
        />
      ) : (
        <div className="fade-in">
          <ActionConfirmationModal
            isOpen={state.showDeleteConfirmation}
            title={
              <Text
                resource={{
                  key: "modal.deleteWithName.title",
                  args: [state.currentEditedRow?.name],
                }}
              />
            }
            caption={<Text resource="modal.deleteVariableMapping.caption" />}
            actionButtonLabel={<Text resource="button.delete" />}
            onClose={() => {
              dispatch(setShowDeleteConfirmation(false));
            }}
            onCancel={() => dispatch(setShowDeleteConfirmation(false))}
            onClickActionButton={deleteMapping}
          />
          <StyledTablePageHeader>
            <StyledHeaderTopRow>
              <StyledVariableMappingsTitle>
                <Text resource="variableMappingGroups.title" />
              </StyledVariableMappingsTitle>
              <SearchRow
                dataType="searchBar.dataType.variableMappings"
                currentCount={state.currentPresetCount}
                totalCount={state.totalPresetCount}
                onSearch={onSearch}
              />
              <Button onClick={onClickCreateMappingButton} style={{ marginLeft: "auto" }}>
                <Text resource="button.createNewVariableMappingGroup" />
              </Button>
            </StyledHeaderTopRow>
            {state.currentPresetCount > 0 && (
              <StyledPaginationContainer>
                <Pagination
                  currentPage={state.currentPage}
                  currentItemsCount={state.currentPresetCount}
                  handlePageSelection={handlePageSelection}
                />
              </StyledPaginationContainer>
            )}
          </StyledTablePageHeader>
        </div>
      )}
      {state.totalPresetCount > 0 || state.isLoading ? (
        <FlexTable
          type={FlexTableType.Table}
          data={{
            headers: GLOSSARIES_HEADERS.map((h, i) => (
              <Text key={i} resource={`variableMappingGroups.table.${h.headerName}`} />
            )).concat(<></>),
            rows: state.variableMappingList?.map((mp) => ({
              contentId: mp.id,
              data: [
                <TextTruncator value={mp.name} id={mp.id} key={mp.id} customWidth="85%" />,
                <span key={`variableCount-${mp.id}`}>{mp.variableCount}</span>,
                <span key={`createdDate-${mp.id}`}>
                  {getInternationalDateFormat(getDayMonthYearFromDate(mp.createdDate))}
                </span>,
                <span key={`last-updated-${mp.id}`}>
                  {getInternationalDateFormat(getDayMonthYearFromDate(mp.lastUpdated))}
                </span>,
              ],
              dotsMenuParams: {
                rowId: mp.id,
                currentEditedRowId: state.currentEditedRow?.id,
                menuContents: getDotsMenuContents(),
                onDotMenuClick: () => dispatch(setCurrentEditedRow(mp)),
              },
            })),
            columnWidths: ["30%", "20%", "22.5%", "22.5%", "5%"],
          }}
          handleDoubleClickRow={(id) => history.push(routes.editSelectedVariableMapping(id))}
          customStyles={{
            rows: {
              padding: "10px",
              backgroundColor: Color.white,
            },
          }}
          initialSorting={{
            columnIndex: GLOSSARIES_HEADERS.findIndex(
              (h) => h.sortingParameter === state.sortColumn
            ),
            order: state.sortOrder,
          }}
          onSortingChange={updateSorting}
          isLoading={state.isLoading}
        />
      ) : (
        <EmptyState
          type={state.emptyStateType}
          image={emptySvg}
          titleKey={"emptyState.noVariableMappings.title"}
          captionKey={"emptyState.noVariableMappings.caption"}
          handleClickCaptionLink={() => dispatch(setSearchTerm(""))}
          customStyles={{ addWidth: true }}
          CreateButton={
            <Button onClick={onClickCreateMappingButton}>
              <Text resource="button.createNewVariableMappingGroup" />
            </Button>
          }
        />
      )}
    </>
  );
};

const StyledPaginationContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
`;

const StyledHeaderTopRow = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
`;

const StyledVariableMappingsTitle = styled(StyledTablePageTitle)`
  font-size: 1em;
`;
