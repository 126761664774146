import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Checkbox } from "components/_inputs/Checkbox";
import { Tooltip } from "components/Tooltip";

import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Color } from "ts/enums/color";

import { isAllSelected } from "utils/userDemographics";
import { getRandomNumber } from "utils/getRandomNumber";

export type ChecklistItem = {
  name: string;
  values: string[];
};

type Props = {
  id: number;
  checklistItem: ChecklistItem;
  selectedValues: ChecklistItem[];
  parentCheckboxLabel?: string;
  isExpandable?: boolean;
  isMenuClosed?: boolean;
  hasTooltip?: boolean;
  updateValues: (updatedItem: ChecklistItem) => void;
};

export const NestedChecklist = ({
  id,
  checklistItem,
  selectedValues,
  parentCheckboxLabel,
  isExpandable = true,
  isMenuClosed,
  hasTooltip = true,
  updateValues,
}: Props) => {
  const [showChildren, setShowChildren] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const generatedId = getRandomNumber();

  const currentlySelectedItem = selectedValues.find((sv) => sv.name == checklistItem.name);

  const getCheckboxVariant = (value?: string): CheckboxCheckedAppearance => {
    if (!currentlySelectedItem) return CheckboxCheckedAppearance.Default;
    const variantCondition = value
      ? checklistItem.values.includes(value)
      : isAllSelected(checklistItem.values, currentlySelectedItem.values);
    return variantCondition ? CheckboxCheckedAppearance.Default : CheckboxCheckedAppearance.Partial;
  };

  const handleMouseOver = (e: React.MouseEvent<HTMLSpanElement>) => {
    setShowTooltip(e.currentTarget.offsetHeight < e.currentTarget.scrollHeight);
  };

  useEffect(() => {
    if (isMenuClosed) setShowChildren(false);
  }, [isMenuClosed]);

  return (
    <>
      <StyledNestedChecklistContainer key={`${id}-${checklistItem.name}`}>
        {isExpandable && (
          <StyledIconContainer
            hasChildren={!!checklistItem.values}
            onClick={() => setShowChildren((show) => !show)}
          >
            <Icon
              type={showChildren ? IconType.minus : IconType.plus}
              size={14}
              color={Color.gray30}
            />
          </StyledIconContainer>
        )}
        <StyledCheckboxContainer hasTooltip={hasTooltip}>
          <Checkbox
            checked={currentlySelectedItem?.values.length > 0}
            checkedAppearance={getCheckboxVariant()}
            onChange={() => updateValues(checklistItem)}
          />
          <span
            data-tooltip-id={`${generatedId}-${id}`}
            onMouseOver={hasTooltip ? handleMouseOver : undefined}
            onMouseOut={() => setShowTooltip(false)}
            onClick={() => updateValues(checklistItem)}
          >
            {parentCheckboxLabel ?? checklistItem.name}
            {showTooltip && (
              <Tooltip
                tooltipId={`${generatedId}-${id}`}
                content={parentCheckboxLabel ?? checklistItem.name}
              />
            )}
          </span>
        </StyledCheckboxContainer>
      </StyledNestedChecklistContainer>
      {(!isExpandable || showChildren) && (
        <StyledChildrenContainer showExpand={isExpandable}>
          {checklistItem.values.map((v, index) => (
            <StyledCheckboxContainer key={`${v}-${index}`} hasTooltip={hasTooltip}>
              <Checkbox
                checked={currentlySelectedItem?.values.includes(v) ?? false}
                checkedAppearance={getCheckboxVariant(v)}
                onChange={() => updateValues({ name: checklistItem.name, values: [v] })}
              />
              <span
                data-tooltip-id={`${generatedId + 1}-${index}`}
                onMouseOver={hasTooltip ? handleMouseOver : undefined}
                onMouseOut={() => setShowTooltip(false)}
                onClick={() => updateValues({ name: checklistItem.name, values: [v] })}
              >
                {v}
                {showTooltip && <Tooltip tooltipId={`${generatedId + 1}-${index}`} content={v} />}
              </span>
            </StyledCheckboxContainer>
          ))}
        </StyledChildrenContainer>
      )}
    </>
  );
};

const StyledNestedChecklistContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
`;

const StyledIconContainer = styled.div<{ hasChildren: boolean }>`
  cursor: ${({ hasChildren }) => (hasChildren ? "pointer" : "default")};
  visibility: ${({ hasChildren }) => (hasChildren ? "visible" : "hidden")};
`;

const StyledCheckboxContainer = styled.div<{ hasTooltip: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;

  span:nth-child(2) {
    ${({ hasTooltip }) =>
      hasTooltip &&
      css`
        max-width: 170px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-lines: 1;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      `}
    font-size: 0.875em;
    cursor: pointer;
  }
`;

const StyledChildrenContainer = styled.div<{ showExpand: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-left: ${({ showExpand }) => (showExpand ? "2.5em" : 0)};
`;
