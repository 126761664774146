import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useAppSelector } from "store";

import { routes } from "routes";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { CommentsAnalyzed } from "./_layouts/blocks/CommentsAnalyzed";
import { Text } from "components/Text";

import { RoleType } from "@explorance/mly-types";
import { ButtonSize } from "ts/enums/button";

export const LicensingPage = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const history = useHistory();

  const navigateToModelManagementPage = () => history.push(routes.modelManagementPage);

  useEffect(() => {
    if (!isAnyAdmin(currentUser?.roleType)) history.push(routes.homePage);
  }, [currentUser?.roleType, history]);

  return (
    <div className="fade-in">
      <StyledLicensingPageHeader>
        <h1>
          <Text resource="licensing.header" />
        </h1>
        {currentUser?.roleType === RoleType.SuperAdmin && (
          <Button size={ButtonSize.md} onClick={navigateToModelManagementPage}>
            <Text resource="licensing.button.manageModel" />
            <Icon type={IconType.chevronRight} size={14} style={{ marginLeft: 6 }} />
          </Button>
        )}
      </StyledLicensingPageHeader>
      <CommentsAnalyzed />
    </div>
  );
};

const StyledLicensingPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  h1 {
    font-size: 16px;
  }
`;
