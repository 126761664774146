import React from "react";
import styled from "styled-components";

import { Text } from "components/Text";
import { Button } from "components/_buttons/Button";
import { Select } from "components/_inputs/Select";

import { DataPreviewTable } from "pages/analysis/[id]/data-source/_layouts/DataPreviewTable";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { useAppDispatch, useAppSelector } from "store";
import { setImportModalOpen } from "store/importProcess/importModalSlice";
import { Color } from "ts/enums/color";
import { voidFunc } from "utils/voidFunc";

export const ImportSettingsStep = () => {
  const state = useAppSelector((state) => state.dataSource);
  const dispatch = useAppDispatch();

  return (
    <>
      <StyledImportSettingsHeader>
        <StyledDescriptionInfo>
          <b>
            <Text resource="modal.import.stepTwo.title" />
          </b>
          <StyledDescriptionSection>
            <Text resource="modal.import.stepTwo.description" />
            <b>
              <Text
                resource={{
                  key: "dataSource.totalCount",
                  args: [`${state.analysisDataSource?.commentsTotalCount}`],
                }}
              />
            </b>
          </StyledDescriptionSection>
        </StyledDescriptionInfo>
        <StyledHeaderSettings>
          <Select
            options={[{ label: "Append and update", value: "Append and update" }]}
            selectedOption={{ label: "Append and update", value: "Append and update" }}
            buttonStyle={{ width: "175px" }}
            dropdownWidth="175px"
            selectLabelKey="modal.import.stepTwo.type"
            dropdownPosition={{ top: 35, right: 0 }}
            handleChange={voidFunc}
          />
          <Select
            options={[{ label: "ID", value: "ID" }]}
            selectedOption={{ label: "ID", value: "ID" }}
            buttonStyle={{ width: "155px" }}
            dropdownWidth="155px"
            dropdownPosition={{ top: 35, right: 0 }}
            selectLabelKey="modal.import.stepTwo.uniqueColumn"
            handleChange={voidFunc}
          />
        </StyledHeaderSettings>
      </StyledImportSettingsHeader>
      <StyledProgressBar>
        <StyledCurrentProgress />
      </StyledProgressBar>
      <StyledDataPreviewTableContainer>
        <DataPreviewTable />
      </StyledDataPreviewTableContainer>
      <StyledActionsSection>
        <Button
          variant={ButtonVariant.outline}
          size={ButtonSize.md}
          onClick={() => dispatch(setImportModalOpen(false))}
        >
          <Text resource="button.close" />
        </Button>
        <Button variant={ButtonVariant.primary} size={ButtonSize.md}>
          <Text resource="button.next" />
        </Button>
      </StyledActionsSection>
    </>
  );
};

const StyledDataPreviewTableContainer = styled.div`
  overflow: auto;
  max-height: 530px;
`;

const StyledDescriptionInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledImportSettingsHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
`;

const StyledHeaderSettings = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledDescriptionSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 5px;
  font-size: 14px;
`;

const StyledActionsSection = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: end;
  flex-direction: row;
  align-items: flex-end;
`;

const StyledProgressBar = styled.div`
  width: calc(100% + 48px);
  height: 5px;
  margin-left: -24px;
  background-color: ${Color.blue20};
  margin-bottom: 12px;
`;

const StyledCurrentProgress = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${Color.green50};
`;
