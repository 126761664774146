import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ImportModalState {
  importModalOpen: boolean;
}

const initialState: ImportModalState = {
  importModalOpen: false,
};

const importModalSlice = createSlice({
  name: "importModal",
  initialState,
  reducers: {
    setImportModalOpen: (state, action: PayloadAction<boolean>) => {
      state.importModalOpen = action.payload;
    },
  },
});

export const { setImportModalOpen } = importModalSlice.actions;

export default importModalSlice.reducer;
